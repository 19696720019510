<template>
<v-container style="margin-top:160px;"  fluid>
  <!-- <v-img src="img/flash.png" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;"></v-img> -->
  <!-- <v-card class="rounded-lg" elevation="10" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;">
    <v-card-title><center><v-img src="img/flash.png" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;"></v-img></center></v-card-title>
  </v-card> -->
  <v-container v-if="menu_active">

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_profile.png"
         
          @click.native="$router.push({name:'Profile'})"
          >Мій профіль
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_balance.png"
         
          @click.native="$router.push({name:'Balance'})"
          >Мої бали
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_stat.png"
         
          @click.native="$router.push({name:'LPR_STAT'})"
          >Статистика
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_emo.png"
          
           @click.native="$router.push({name:'Withdraw_sel'})"
          >Емоції
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_info.png"
         
          @click.native="$router.push({name:'News_sel'})"
          >iTeam новини
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/gl_ico.png"
          @click.native="$router.push({name:'Info_sel'})"
          >Корисна інформація
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_news.png"
          @click.native="$router.push({name:'TPQ'})/*$emit('pushe', {header:'Повідомлення',text:'Функція недоступна, додаток працює у обмеженому режимі'});*/" 
          >Комунікація
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_exit.png" 
           
          @click.native="logout"
          >
          Вийти
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    </v-container>







<v-dialog
        v-model="dialog_netw_confirm"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Мережевий iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>  Запит очікує підтвердження модератором.</b></p>
              <p>Ви зможете продовжити реєстрацію та виконувати завдання після підтвердження.</p>
                </v-col>
             </v-row>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_netw_confirm = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="dialog_sku_confirm"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Ассортимент iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>  Запит очікує підтвердження модератором.</b></p>
              <p>Ви зможете виконувати завдання після підтвердження.</p>
                </v-col>
             </v-row>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_sku_confirm = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="pass_change"
        width="300" style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Встановіть пароль</v-card-title>
          <v-card-text>
            <!-- <v-row fluid>
             
                <v-col cols="12">
                  <v-text-field
                    v-model="password_old"
                    name="Password1n"
                    label="Cтарий пароль"
                    solo
                    rounded
                    @click:append="show1 = !show1"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  
                  ></v-text-field>
                </v-col>
               
              </v-row> -->
              <v-row fluid>
                
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new"
                    name="Password1n"
                    label="Новий пароль"
                    solo
                    rounded
                    @click:append="show3 = !show3"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                  
                  ></v-text-field>
                </v-col>
                
              </v-row>
              <v-row fluid>
               
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new_confirm"
                    name="Password2n"
                    label="Підтвердження паролю"
                    solo
                    rounded
                    @click:append="show2 = !show2"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
               
              </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            
            <v-btn
              color="primary"
              text
              @click="pass_set_new"
            >
              Встановити
            </v-btn>
            <!-- <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="pass_change = false"
            >
              Відмінити
            </v-btn> -->
          </v-card-actions>
      </v-card>
</v-dialog>




</v-container>
</template>

<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
import btnsqr from "../components/btnsqr.vue";
//import l_switch from "../components/lang_switch.vue";
export default {
  watch:{
    

    

  },
  components:{btnsqr},
  computed: {
   
  },
  data: () => ({
    dialog_netw_confirm:false,
    dialog_sku_confirm:false,
    sku_lock:false,
    network_lock:false,
    deleted_lock:false,
    task_pending:0,
    menu_active:true,
    pass_change:false,
    password_old:null,
    password_new:null,
    password_new_confirm:null,
    show1: false,
    show2: false,
    show3: false,
     g:{long:'',lat:'',coord_state:''},
    
  }), 
  methods: {
    navOnSuccess(position) {
                 console.log('Latitude: '          + position.coords.latitude          + '\n' +
                        'Longitude: '         + position.coords.longitude         + '\n' +
                        'Altitude: '          + position.coords.altitude          + '\n' +
                        'Accuracy: '          + position.coords.accuracy          + '\n' +
                        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
                        'Heading: '           + position.coords.heading           + '\n' +
                        'Speed: '             + position.coords.speed             + '\n' +
                        'Timestamp: '         + position.timestamp                + '\n');
                        
                        
                        this.g.long = position.coords.longitude
                        this.g.lat = position.coords.latitude
                        this.g.coord_state='success'
                        console.log('long: '+this.g.long+': lat:'+this.g.lat)
                        //alert('long: '+this.g.long+': lat:'+this.g.lat)
                        console.log(this.g);

              },

              navOnError(error) {
                  console.log('NAV_FAIL: code: '    + error.code    + '\n' +
                        'message: ' + error.message + '\n')
              },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    pass_set_new:function(){
            axios
      .post(
        API_LOCATION,
        {
          
          action: "pass_set_new",
          pass_old:this.password_old,
          pass_new1: this.password_new,
          pass_new2: this.password_new_confirm,
          temp:true
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.pass_set!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          alert(this.$t('success_change'));
          this.pass_change=false;
          this.logout();

        }
      });


    
  },
    check_prof: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "check_prof"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==-1)         {
                this.$emit('pushe', {header:'Повідомлення',text:'Заповніть Ваш профіль, вкажіть місто, додатковий телефон та оберіть іконку.'});
              } 
              
        
      });

    },
    get_k_type: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "k_type"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==9)         {
                this.$router.push("/Asssel");
              } 
              else if(resp.data==10)     {
                 this.$router.push("/Asssel");
              }
        
      });

    },
    get_pending_task: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "pending_task"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              this.task_pending=resp.data;
              console.log('PENDING:'+resp.data);
              
        
      });

    },
    get_pending_network_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_network_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_netw_confirm=true;
                this.network_lock=true
              }
        
      });

    },
    get_pending_sku_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_sku_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_sku_confirm=true;
                this.sku_lock=true;
              }
        
      });

    },
    get_pending_sku_change: function(){

    },
    get_pending_reg_complete: function(){

    }
    
  },
  created() {
    this.check_prof();
          // if (navigator.geolocation)
          //     {
          //     navigator.geolocation.getCurrentPosition(this.navOnSuccess, this.navOnError,{enableHighAccuracy:false,maximumAge:Infinity, timeout:60000})
          //      console.log('start_nav_detect')
          //     }
          //     else
          //     {
          //       console.log('geolocation_not_supported')
          //     }

    this.$store.dispatch("profileset");
    
    this.$store.dispatch("page_title_set", "Головна");  
    
    axios
      .post(
        API_LOCATION,
        {
          
          action: "api_check"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              console.log(resp.data);         
        
      });
    this.get_k_type();
    this.get_pending_sku_confirm();
    this.get_pending_network_confirm();









  },
};
</script>


<i18n>
{
  "ua": {
    "wrong_old":"Помилковий старий пароль",
"pass not match":"Паролі не співпадають",
    "pass insecure":"Довжина паролю має бути від 6 символів",
    "success_change":"Пароль успішно змінено, увійдіть до додатку з новим паролем",
"close":"Закрити"

  }
}
</i18n>